/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConsentsBody } from '../models/ConsentsBody';
import type { IBAN } from '../models/IBAN';
import type { MerchantDetails } from '../models/MerchantDetails';
import type { MerchantOnboardingInitRequest } from '../models/MerchantOnboardingInitRequest';
import type { MerchantOnboardingInitResponse } from '../models/MerchantOnboardingInitResponse';
import type { MerchantOnboardingPrefilledFormData } from '../models/MerchantOnboardingPrefilledFormData';
import type { MerchantOnboardingStatus } from '../models/MerchantOnboardingStatus';
import type { OnboardingTranslatedConsent } from '../models/OnboardingTranslatedConsent';
import type { SupportedLanguage } from '../models/SupportedLanguage';
import type { TwoFactorConfirmRequest } from '../models/TwoFactorConfirmRequest';
import type { VerifyEmailInitRequest } from '../models/VerifyEmailInitRequest';
import type { VerifyPhoneInitRequest } from '../models/VerifyPhoneInitRequest';
import type { WebshopPlatform } from '../models/WebshopPlatform';
import type { WebshopPlatformBody } from '../models/WebshopPlatformBody';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OnboardingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns MerchantOnboardingInitResponse Ok
     * @throws ApiError
     */
    public init(
        requestBody: MerchantOnboardingInitRequest,
    ): Observable<MerchantOnboardingInitResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/init',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public emailInit(
        onboardingToken: string,
        requestBody: VerifyEmailInitRequest,
    ): Observable<{
        firstResendAvailableAt: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/emailVerify/init',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public emailConfirm(
        onboardingToken: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/emailVerify/confirm',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public phoneInit(
        onboardingToken: string,
        requestBody: VerifyPhoneInitRequest,
    ): Observable<{
        firstResendAvailableAt: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/phoneVerify/init',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public phoneConfirm(
        onboardingToken: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/phoneVerify/confirm',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @returns MerchantOnboardingPrefilledFormData Ok
     * @throws ApiError
     */
    public getPrefilledFormData(
        onboardingToken: string,
    ): Observable<MerchantOnboardingPrefilledFormData> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/onboarding/prefilledFormData',
            headers: {
                'onboarding-token': onboardingToken,
            },
        });
    }

    /**
     * @param onboardingToken
     * @returns MerchantOnboardingStatus Form data
     * @throws ApiError
     */
    public getFormData(
        onboardingToken: string,
    ): Observable<MerchantOnboardingStatus> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/onboarding/formData',
            headers: {
                'onboarding-token': onboardingToken,
            },
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public submitFormData(
        onboardingToken: string,
        requestBody: MerchantDetails,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/formData',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @returns string Ok
     * @throws ApiError
     */
    public getBankAccounts(
        onboardingToken: string,
    ): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/onboarding/bankAccounts',
            headers: {
                'onboarding-token': onboardingToken,
            },
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public saveBankAccount(
        onboardingToken: string,
        requestBody: {
            bankAccount: IBAN;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/bankAccount',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WebshopPlatform Ok
     * @throws ApiError
     */
    public webshopPlatforms(): Observable<Array<WebshopPlatform>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/onboarding/webshopPlatforms',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public saveWebshopPlatform(
        onboardingToken: string,
        requestBody: WebshopPlatformBody,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/webshopPlatform',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onboardingToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public consents(
        onboardingToken: string,
        requestBody: ConsentsBody,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/consents',
            headers: {
                'onboarding-token': onboardingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param xLanguage
     * @returns any Ok
     * @throws ApiError
     */
    public getMerchantTc(
        xLanguage?: SupportedLanguage,
    ): Observable<{
        contract: OnboardingTranslatedConsent;
        pp: OnboardingTranslatedConsent;
        tnc: OnboardingTranslatedConsent;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/onboarding/tc',
            headers: {
                'x-language': xLanguage,
            },
        });
    }

    /**
     * @param onboardingToken
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public uploadFile(
        onboardingToken: string,
        formData: {
            file: Blob;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/onboarding/uploadFile',
            headers: {
                'onboarding-token': onboardingToken,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}