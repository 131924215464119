/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { OfflineStore } from '../models/OfflineStore';
import type { Omit_OfflineStore_offlineStoreId_or_merchantId_ } from '../models/Omit_OfflineStore_offlineStoreId_or_merchantId_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OfflineStoresService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns OfflineStore Ok
     * @throws ApiError
     */
    public listV1(): Observable<Array<OfflineStore>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/offlineStores',
        });
    }

    /**
     * @param offlineStoreId
     * @returns any Ok
     * @throws ApiError
     */
    public getV1(
        offlineStoreId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/offlineStores/{offlineStoreId}',
            path: {
                'offlineStoreId': offlineStoreId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createV1(
        requestBody: Omit_OfflineStore_offlineStoreId_or_merchantId_,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/offlineStores/{offlineStoreId}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param offlineStoreId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateV1(
        offlineStoreId: string,
        requestBody: Omit_OfflineStore_offlineStoreId_or_merchantId_,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/offlineStores/{offlineStoreId}',
            path: {
                'offlineStoreId': offlineStoreId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param offlineStoreId
     * @returns void
     * @throws ApiError
     */
    public removeV1(
        offlineStoreId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/offlineStores/{offlineStoreId}',
            path: {
                'offlineStoreId': offlineStoreId,
            },
        });
    }

}