/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConsentDefinition } from '../models/ConsentDefinition';
import type { CreateOrUpdateConsentDefinitionRequest } from '../models/CreateOrUpdateConsentDefinitionRequest';
import type { MerchantConsentDefinitions } from '../models/MerchantConsentDefinitions';
import type { Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_ } from '../models/Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConsentDefinitionsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public listAll(): Observable<Array<ConsentDefinition>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/consentDefinitions',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public create(
        requestBody: CreateOrUpdateConsentDefinitionRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/consentDefinitions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createWithCustomId(
        id: MerchantConsentDefinitions,
        requestBody: CreateOrUpdateConsentDefinitionRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/consentDefinitions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/consentDefinitions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public getOrThrow(
        id: string,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/consentDefinitions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public delete(
        id: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/consentDefinitions/{id}',
            path: {
                'id': id,
            },
        });
    }

}