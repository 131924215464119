/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AuthConfirmAndTrustedBrowser } from '../models/AuthConfirmAndTrustedBrowser';
import type { AuthConfirmRequest } from '../models/AuthConfirmRequest';
import type { Merchant } from '../models/Merchant';
import type { MerchantAuthInit } from '../models/MerchantAuthInit';
import type { MerchantUser } from '../models/MerchantUser';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LoginService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public auth(
        requestBody: MerchantAuthInit,
    ): Observable<{
        merchantLoginToken: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/login/init',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public emailConfirm(
        requestBody: AuthConfirmRequest,
    ): Observable<{
        phoneNumber: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/login/emailConfirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public phoneConfirm(
        requestBody: AuthConfirmAndTrustedBrowser,
    ): Observable<{
        merchantLoginToken: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/login/phoneConfirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MerchantUser Ok
     * @throws ApiError
     */
    public whoami(): Observable<MerchantUser> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/login/whoami',
        });
    }

    /**
     * @returns any Ok
     * @throws ApiError
     */
    public whoamiV1(): Observable<{
        merchantUser: MerchantUser;
        merchant: Merchant;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/login/whoami',
        });
    }

}