import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IntegrationProfileResponse } from 'app/api/generated';
import { IntegrationProfilesManagementService } from 'app/modules/integration-profiles/data-access/integration-profiles-management.service';
import { IntegrationProfilesQuery } from 'app/modules/integration-profiles/data-access/integration-profiles.query';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-integration-profile',
  templateUrl: './select-integration-profile.component.html',
})
export class SelectIntegrationProfileComponent implements OnInit {
  @Output() profileChanged = new EventEmitter<string>();

  private readonly _lsProfileIdKey = 'selectedProfileId';
  public profiles$: Observable<IntegrationProfileResponse[]>;
  public profileId: string;

  constructor(
    private readonly integrationProfileService: IntegrationProfilesManagementService,
    private readonly queryIntegrationProfile: IntegrationProfilesQuery
  ) {}

  ngOnInit(): void {
    this.integrationProfileService.getIntegrationProfiles();
    this.profiles$ = this.queryIntegrationProfile.profiles$.pipe(
      tap((profiles) => {
        if (profiles.length && !this.profileId) {
          const ls = localStorage.getItem(this._lsProfileIdKey);
          const exist = profiles.find((a) => a.integrationProfileId === ls);
          this.profileId = exist ? ls : profiles[0].integrationProfileId;
          localStorage.setItem(this._lsProfileIdKey, this.profileId);
          this.profileChanged.emit(this.profileId);
        }
      })
    );
  }

  public changedProfile(profile: any) {
    localStorage.setItem(this._lsProfileIdKey, this.profileId);
    this.profileChanged.emit(this.profileId);
  }
}
