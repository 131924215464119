import { Injectable } from '@angular/core';
import { Observable, of, race, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoginService, MerchantUser } from 'app/api/generated';
import { Router } from '@angular/router';
import { UserExt } from './user.types';

const adminPrivilege = 'MERCHANT_ADMIN';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<UserExt> = new ReplaySubject<UserExt>(1);

  isAdmin$ = this._user.asObservable().pipe(map((user) => user.merchantUser.merchantUserPrivileges.includes(adminPrivilege)));

  constructor(private readonly backend: LoginService, private readonly router: Router) {}

  set user(value: UserExt) {
    this._user.next(value);
  }

  get user$(): Observable<UserExt> {
    return race([this._user.asObservable(), this.get()]);
  }

  get(): Observable<UserExt> {
    return this.backend.whoamiV1().pipe(
      catchError(() => {
        localStorage.removeItem('accessToken');
        this.router.navigate(['sign-in']);
        throw of();
      }),
      tap((user) => {
        this._user.next(user);
      })
    );
  }

  update(user: UserExt): Observable<any> {
    return of();
  }
}
