/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EditMerchantUserRequest } from '../models/EditMerchantUserRequest';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_MerchantUser_ } from '../models/Paginated_MerchantUser_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param last
     * @returns Paginated_MerchantUser_ Ok
     * @throws ApiError
     */
    public listUsers(
        last?: EncryptedLastItem,
    ): Observable<Paginated_MerchantUser_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user',
            query: {
                'last': last,
            },
        });
    }

    /**
     * @param merchantUserId
     * @returns void
     * @throws ApiError
     */
    public deleteUser(
        merchantUserId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/user/{merchantUserId}',
            path: {
                'merchantUserId': merchantUserId,
            },
        });
    }

    /**
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public editUser(
        merchantUserId: string,
        requestBody: EditMerchantUserRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/user/{merchantUserId}',
            path: {
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}