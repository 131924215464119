import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { InputMaskModule } from '@ngneat/input-mask';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { PhonePipe } from './pipes/phone-format.pipe';
import { TaxPipe } from './pipes/tax-format.pipe';
import { SelectIntegrationProfileComponent } from './components/select-integration-profile/select-integration-profile.component';
import { MatSelectModule } from '@angular/material/select';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeFr from '@angular/common/locales/fr';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ShowForRoleDirective } from 'app/core/auth/directives/show-for-role-directive';
import { DetectScrollToEndDirective } from './directives/detect-scroll-to-end-directive';
registerLocaleData(localePl);
registerLocaleData(localeFr);

@NgModule({
  declarations: [CurrencyFormatPipe, PhonePipe, TaxPipe, SelectIntegrationProfileComponent, ThousandSeparatorPipe, ShowForRoleDirective, DetectScrollToEndDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, InputMaskModule, MatSelectModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    InputMaskModule,
    CurrencyFormatPipe,
    PhonePipe,
    TaxPipe,
    SelectIntegrationProfileComponent,
    ThousandSeparatorPipe,
    ShowForRoleDirective,
    DetectScrollToEndDirective
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedModule {}
