import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[enableControl]'
})
export class EnableControlDirective implements OnChanges {

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.enableControl) {
      this.enableOrDisableControl(changes.enableControl.currentValue);
    }
  }

  @Input() enableControl = false;

  enableOrDisableControl(enable : boolean ) {
    if (!this.ngControl || !this.ngControl.control) {
      console.log('Enable control directive not yet instantiated');
      return;
    }
    const action = enable ? 'enable' : 'disable';

    // todo: should check if parent component is disabled, and prevent enable() if so
    this.ngControl.control[action]();
  }

  constructor( private ngControl : NgControl ) {
  }

}
