/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { MerchantUserPrivilegeEnum } from 'app/shared/enums/enums';

export const commonNavigation: FuseNavigationItem[] = [
  {
    id: 'orders',
    title: 'Orders',
    type: 'basic',
    link: '/orders',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
      MerchantUserPrivilegeEnum.MERCHANT_CUSTOMER_SERVICE,
      MerchantUserPrivilegeEnum.MERCHANT_IT,
      MerchantUserPrivilegeEnum.MERCHANT_ANALYTICS,
    ]
  },
  {
    id: 'settlements',
    title: 'Settlements',
    type: 'basic',
    link: '/settlements',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
      MerchantUserPrivilegeEnum.MERCHANT_IT
    ]
  },
  {
    id: 'invoicing',
    title: 'Invoicing',
    type: 'basic',
    link: '/invoices',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
      MerchantUserPrivilegeEnum.MERCHANT_IT
    ]
  },
  {
    id: 'wallets',
    title: 'Wallets',
    type: 'basic',
    link: '/wallets',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
      MerchantUserPrivilegeEnum.MERCHANT_IT
    ]
  },
  {
    id: 'offlineStores',
    title: 'Offline stores',
    type: 'basic',
    link: '/locations',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_IT, 
      MerchantUserPrivilegeEnum.MERCHANT_CUSTOMER_SERVICE
    ]
  },
  {
    id: 'users',
    title: 'Users',
    type: 'basic',
    link: '/users',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN,
      MerchantUserPrivilegeEnum.MERCHANT_IT,
    ]
  },
  {
    id: 'integrationProfiles',
    title: 'Integration Profiles',
    type: 'basic',
    link: '/integrationProfiles',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN,
    ]
  },
  {
    id: 'logs',
    title: 'Logs',
    type: 'basic',
    link: '/logs/getBasket',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN
    ]
  },
  {
    id: 'logout',
    title: 'Logout',
    type: 'basic',
    link: '/sign-out',
    permissions: [
      MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
      MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
      MerchantUserPrivilegeEnum.MERCHANT_CUSTOMER_SERVICE,
      MerchantUserPrivilegeEnum.MERCHANT_IT,
      MerchantUserPrivilegeEnum.MERCHANT_ANALYTICS,
    ]
  },
];

export const defaultNavigation: FuseNavigationItem[] = [...commonNavigation];
export const compactNavigation: FuseNavigationItem[] = [...commonNavigation];
export const futuristicNavigation: FuseNavigationItem[] = [...commonNavigation];
export const horizontalNavigation: FuseNavigationItem[] = [...commonNavigation];
