import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MerchantInvoice } from 'app/api/generated/models/MerchantInvoice';

export interface InvoiceState extends EntityState<MerchantInvoice, string>, ActiveState {
  last: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Invoices', idKey: 'ldate' })
export class InvoicesStore extends EntityStore<InvoiceState, MerchantInvoice, string> {
  constructor() {
    super();
  }
}
