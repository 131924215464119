import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IntegrationProfileResponse } from 'app/api/generated';
import {
  IntegrationProfilesStore,
  IntegrationProfileState,
} from 'app/modules/integration-profiles/data-access/integration-profiles.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IntegrationProfilesQuery extends QueryEntity<IntegrationProfileState> {
  loading$: Observable<boolean>;
  error$: Observable<any>;
  profiles$: Observable<IntegrationProfileResponse[]>;
  selected$: Observable<IntegrationProfileResponse>;

  constructor(protected store: IntegrationProfilesStore) {
    super(store);

    this.loading$ = this.selectLoading();
    this.error$ = this.selectError();
    this.profiles$ = this.selectAll();
    this.selected$ = this.selectActive();
  }
}
