<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <mat-drawer-container class="flex-auto h-full">
    <!-- DRAWERS -->
    <mat-drawer class="w-1/2 dark:bg-gray-900" [autoFocus]="false" [mode]="'over'" [position]="'start'"
      [(opened)]="leftDrawerOpened" (closed)="leftDrawerOpenedChange.emit(false)">
      <ng-content select="[leftDrawer]"></ng-content>
    </mat-drawer>
    <mat-drawer class="w-1/2 dark:bg-gray-900" [autoFocus]="false" [mode]="'over'" [position]="'end'"
      [(opened)]="rightDrawerOpened" (closed)="rightDrawerOpenedChange.emit(false)">
      <ng-content select="[rightDrawer]"></ng-content>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col">
      <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent">
        <div class="flex-1 min-w-0">
          <div class="mt-2">
            <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
              {{ pageTitle }}
            </h2>
            <ng-container *ngIf="subTitle">
              <p>
                <span [innerHTML]="subTitle"></span>
                <mat-icon style="pointer-events: auto; transform: scale(.7); position: relative; top: 5px"
                  [matTooltip]="tooltip" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
              </p>
            </ng-container>

          </div>
        </div>
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
          <!-- BUTTONS -->
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>

      <div class="flex-auto -mt-16 rounded-2xl shadow overflow-y-auto bg-card m-10" cdkScrollable>
        <div class="h-100 min-h-100 max-h-800 rounded-2xl">
          <!-- CONTENT -->
          <ng-content select="[content]"></ng-content>
          <oa-empty-state *ngIf="emptyState"></oa-empty-state>
          <oa-error-state *ngIf="errorState" [message]="errorMsg"></oa-error-state>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>