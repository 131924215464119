/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DeliveryProvider } from '../models/DeliveryProvider';
import type { DeliveryProviderConfig } from '../models/DeliveryProviderConfig';
import type { MerchantDeliveryProvider } from '../models/MerchantDeliveryProvider';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DeliveryProviderService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public deliveryProviderConfigCreate(
        requestBody: {
            deliveryProviderId: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/deliveryProviderConfig',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DeliveryProviderConfig Ok
     * @throws ApiError
     */
    public deliveryProviderConfigGetAll(): Observable<Array<DeliveryProviderConfig>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deliveryProviderConfig',
        });
    }

    /**
     * @param providerId
     * @returns DeliveryProviderConfig Ok
     * @throws ApiError
     */
    public deliveryProviderConfigGet(
        providerId: string,
    ): Observable<DeliveryProviderConfig> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deliveryProviderConfig/{providerId}',
            path: {
                'providerId': providerId,
            },
        });
    }

    /**
     * @param providerId
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public deliveryProviderConfigUpdate(
        providerId: string,
        requestBody: DeliveryProviderConfig,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/deliveryProviderConfig/{providerId}',
            path: {
                'providerId': providerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param providerId
     * @returns any Ok
     * @throws ApiError
     */
    public deliveryProviderConfigRemove(
        providerId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/deliveryProviderConfig/{providerId}',
            path: {
                'providerId': providerId,
            },
        });
    }

    /**
     * @returns any Ok
     * @throws ApiError
     */
    public deliveryProvidersWithConfigGetAll(): Observable<{
        otherProviders: Array<DeliveryProvider>;
        merchantProviders: Array<MerchantDeliveryProvider>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deliveryProvidersWithConfig',
        });
    }

}