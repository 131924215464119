import { Injectable } from '@angular/core';
import { OaNotificationsService } from '@openapp';
import { IntegrationProfilesService } from 'app/api/generated';
import { Observable } from 'rxjs';
import { InvoicesStore } from './invoices.store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  last: string = undefined;

  constructor(
    private readonly store: InvoicesStore,
    private readonly backend: IntegrationProfilesService,
    private readonly notifications: OaNotificationsService
  ) {}

  private async execute(action: () => Promise<void>, onError: (err) => void | Promise<void>) {
    this.store.setLoading(true);
    try {
      await action().finally(() => this.store.setLoading(false));
    } catch (e) {
      await onError(e);
    }
  }

  async getInvoices(profileId: string, last?: string) {
    await this.execute(
      async () => {
        const data = await this.backend.getInvoicesForIntegrationProfile(profileId, this.last).toPromise();
        const { last, values } = data;
        this.last = last;
        this.store.update({ last: last });
        this.store.add(values);
      },
      (error) => {
        this.store.setError(error);
      }
    );
  }

  download(profileIntegrationId: string, id: string): Observable<any> {
    this.store.setLoading(true);
    return this.backend.downloadInvoiceForIntegrationProfile(profileIntegrationId, id).pipe(
      tap(() => this.store.setLoading(false))
    );
  }

  resetStore() {
    this.store.set([]);
    this.store.reset();
  }
}
