import { Injectable } from '@angular/core';
import { OaNotificationsService } from '@openapp';
import { IntegrationProfilesStore } from 'app/modules/integration-profiles/data-access/integration-profiles.store';
import {
  CreateCredentialOneTimeResponse,
  CreateCredentialRequest,
  CreateIntegrationProfile,
  CredentialResponse,
  IntegrationProfileResponse,
  IntegrationProfilesService,
  UpdateIntegrationProfileRequest,
} from 'app/api/generated';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntegrationProfilesManagementService {
  constructor(
    private readonly store: IntegrationProfilesStore,
    private readonly backend: IntegrationProfilesService,
    private readonly notifications: OaNotificationsService
  ) {}

  private async execute(action: () => Promise<void>, onError: (err) => void | Promise<void>) {
    this.store.setLoading(true);
    try {
      await action().finally(() => this.store.setLoading(false));
    } catch (e) {
      await onError(e);
    }
  }

  async getIntegrationProfiles() {
    await this.execute(
      async () => {
        const profiles = await this.backend.listIntegrationProfiles().toPromise();
        this.store.set(profiles);
      },
      (error) => {
        this.store.setError(error);
      }
    );
  }

  updateIntegrationProfile(id: string, request: UpdateIntegrationProfileRequest): Observable<any> {
    const urlsWithoutEmptyValues = { ...request.urls };
    Object.keys(urlsWithoutEmptyValues).forEach((key) => {
      if (urlsWithoutEmptyValues[key] === '') {
        delete urlsWithoutEmptyValues[key];
      }
    });
    return this.backend.updateIntegrationProfile(id, {
      ...request,
      urls: urlsWithoutEmptyValues,
    });
  }

  async createIntegrationProfile(createProfile: CreateIntegrationProfile) {
    await this.execute(
      async () => {
        const profile = await this.backend.createIntegrationProfile(createProfile).toPromise();
        this.store.add(profile);
      },
      (err) => {
        this.notifications.push('Error creating profile');
      }
    );
  }

  async createCredential(profile: IntegrationProfileResponse, create: CreateCredentialRequest) {
    let credential: CreateCredentialOneTimeResponse;
    await this.execute(
      async () => {
        const createCopy = { ...create };
        if (createCopy.expiryAt === '') {
          delete createCopy.expiryAt;
        }

        credential = await this.backend.createCredential(profile.integrationProfileId, createCopy).toPromise();

        this.store.update(profile.integrationProfileId, {
          openAppCredentials: [
            ...profile.openAppCredentials,
            {
              credentialId: credential.credentialId,
              name: credential.name,
              expiryAt: credential.expiryAt,
              createdAt: credential.createdAt,
            },
          ],
        });
      },
      (err) => {
        this.notifications.push('Error creating profile');
      }
    );
    return credential;
  }

  async deleteProfile(id: string) {
    await this.execute(
      async () => {
        await this.backend.deactivateIntegrationProfile(id).toPromise();
        this.store.remove(id);
      },
      (err) => {
        this.notifications.push('Error deleting profile');
      }
    );
  }

  selectProfile(id: string) {
    this.store.setActive(id);
  }

  async deleteCredential(profile: IntegrationProfileResponse, credential: CredentialResponse) {
    await this.execute(
      async () => {
        await this.backend.deleteCredential(profile.integrationProfileId, credential.credentialId).toPromise();
        this.store.update(profile.integrationProfileId, {
          openAppCredentials: profile.openAppCredentials.filter((c) => c.credentialId !== credential.credentialId),
        });
      },
      (err) => {
        this.notifications.push('Error deleting credential');
      }
    );
  }
}
