import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';
import { MerchantUserPrivilegeEnum } from './shared/enums/enums';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'orders',
  },
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'orders',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/login/login.module').then((m) => m.AuthLoginModule),
      },
      {
        path: 'login',
        loadChildren: () => import('app/modules/auth/login/login.module').then((m) => m.AuthLoginModule),
      },
      {
        path: 'quick-login',
        loadChildren: () => import('app/modules/auth/quick-login/quick-login.module').then((m) => m.QuickLoginModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
      {
        path: 'onboarding',
        loadChildren: () => import('app/modules/merchant-onboarding/merchant-onboarding.module').then((m) => m.MerchantOnboardingModule),
      },
      {
        path: 'user-activation',
        loadChildren: () => import('app/modules/user-activation/user-activation.module').then((m) => m.UserActivationModule),
      },
      {
        path: 'credential-change',
        loadChildren: () => import('app/modules/user-activation/user-activation.module').then((m) => m.UserActivationModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Post-auth routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'orders',
        canLoad: [AuthGuard],
        data: {
          role: [
            MerchantUserPrivilegeEnum.MERCHANT_ADMIN, 
            MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, 
            MerchantUserPrivilegeEnum.MERCHANT_CUSTOMER_SERVICE, 
            MerchantUserPrivilegeEnum.MERCHANT_ANALYTICS, 
            MerchantUserPrivilegeEnum.MERCHANT_IT
          ]
        },
        loadChildren: () => import('app/modules/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'wallets',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN, MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, MerchantUserPrivilegeEnum.MERCHANT_IT] },
        loadChildren: () => import('app/modules/wallets/wallets.module').then((m) => m.WalletsModule),
      },
      {
        path: 'logs',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN] },
        loadChildren: () => import('app/modules/logs/logs.module').then((m) => m.LogsModule),
      },
      {
        path: 'locations',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN, MerchantUserPrivilegeEnum.MERCHANT_IT, MerchantUserPrivilegeEnum.MERCHANT_CUSTOMER_SERVICE] },
        loadChildren: () => import('app/modules/locations/locations.module').then((m) => m.LocationsModule),
      },
      {
        path: 'integrationProfiles',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN] },
        loadChildren: () => import('app/modules/integration-profiles/integration-profiles.module').then((m) => m.IntegrationProfilesModule),
      },
      {
        path: 'settlements',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN, MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, MerchantUserPrivilegeEnum.MERCHANT_IT] },
        loadChildren: () => import('app/modules/settlements/settlements.module').then((m) => m.SettlementsModule),
      },
      {
        path: 'invoices',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN, MerchantUserPrivilegeEnum.MERCHANT_ACCOUNTING, MerchantUserPrivilegeEnum.MERCHANT_IT] },
        loadChildren: () => import('app/modules/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'users',
        canLoad: [AuthGuard],
        data: { role: [MerchantUserPrivilegeEnum.MERCHANT_ADMIN, MerchantUserPrivilegeEnum.MERCHANT_IT] },
        loadChildren: () => import('app/modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule),
      },
    ],
  },
  {
    path: 'onboarding-old',
    component: EmptyLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
    ],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'orders',
  },
];
