import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AppWalletTrxV4 } from 'app/api/generated';
import { WalletsState, WalletsStore } from './wallets.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WalletsQuery extends QueryEntity<WalletsState, AppWalletTrxV4> {
  loading$: Observable<boolean>;
  error$: Observable<any>;
  list$: Observable<AppWalletTrxV4[]>;
  selected$: Observable<AppWalletTrxV4>;
  last$: Observable<string>;

  constructor(protected store: WalletsStore) {
    super(store);
    this.loading$ = this.selectLoading();
    this.error$ = this.selectError();
    this.list$ = this.selectAll();
    this.selected$ = this.selectActive();
    this.last$ = this.select((state) => state.last);
  }
}
