/* eslint-disable */
import * as moment from 'moment';

export const analytics = {
  visitors: {
    series: {
      'this-year': [
        {
          name: 'Visitors',
          data: [
            {
              x: moment().subtract(12, 'months').day(1).toDate(),
              y: 4884,
            },
            {
              x: moment().subtract(12, 'months').day(4).toDate(),
              y: 5351,
            },
            {
              x: moment().subtract(12, 'months').day(7).toDate(),
              y: 5293,
            },
            {
              x: moment().subtract(12, 'months').day(10).toDate(),
              y: 4908,
            },
            {
              x: moment().subtract(12, 'months').day(13).toDate(),
              y: 5027,
            },
            {
              x: moment().subtract(12, 'months').day(16).toDate(),
              y: 4837,
            },
            {
              x: moment().subtract(12, 'months').day(19).toDate(),
              y: 4484,
            },
            {
              x: moment().subtract(12, 'months').day(22).toDate(),
              y: 4071,
            },
            {
              x: moment().subtract(12, 'months').day(25).toDate(),
              y: 4124,
            },
            {
              x: moment().subtract(12, 'months').day(28).toDate(),
              y: 4563,
            },
            {
              x: moment().subtract(11, 'months').day(1).toDate(),
              y: 3820,
            },
            {
              x: moment().subtract(11, 'months').day(4).toDate(),
              y: 3968,
            },
            {
              x: moment().subtract(11, 'months').day(7).toDate(),
              y: 4102,
            },
            {
              x: moment().subtract(11, 'months').day(10).toDate(),
              y: 3941,
            },
            {
              x: moment().subtract(11, 'months').day(13).toDate(),
              y: 3566,
            },
            {
              x: moment().subtract(11, 'months').day(16).toDate(),
              y: 3853,
            },
            {
              x: moment().subtract(11, 'months').day(19).toDate(),
              y: 3853,
            },
            {
              x: moment().subtract(11, 'months').day(22).toDate(),
              y: 4069,
            },
            {
              x: moment().subtract(11, 'months').day(25).toDate(),
              y: 3879,
            },
            {
              x: moment().subtract(11, 'months').day(28).toDate(),
              y: 4298,
            },
            {
              x: moment().subtract(10, 'months').day(1).toDate(),
              y: 4355,
            },
            {
              x: moment().subtract(10, 'months').day(4).toDate(),
              y: 4065,
            },
            {
              x: moment().subtract(10, 'months').day(7).toDate(),
              y: 3650,
            },
            {
              x: moment().subtract(10, 'months').day(10).toDate(),
              y: 3379,
            },
            {
              x: moment().subtract(10, 'months').day(13).toDate(),
              y: 3191,
            },
            {
              x: moment().subtract(10, 'months').day(16).toDate(),
              y: 2968,
            },
            {
              x: moment().subtract(10, 'months').day(19).toDate(),
              y: 2957,
            },
            {
              x: moment().subtract(10, 'months').day(22).toDate(),
              y: 3313,
            },
            {
              x: moment().subtract(10, 'months').day(25).toDate(),
              y: 3708,
            },
            {
              x: moment().subtract(10, 'months').day(28).toDate(),
              y: 3586,
            },
            {
              x: moment().subtract(9, 'months').day(1).toDate(),
              y: 3965,
            },
            {
              x: moment().subtract(9, 'months').day(4).toDate(),
              y: 3901,
            },
            {
              x: moment().subtract(9, 'months').day(7).toDate(),
              y: 3410,
            },
            {
              x: moment().subtract(9, 'months').day(10).toDate(),
              y: 3748,
            },
            {
              x: moment().subtract(9, 'months').day(13).toDate(),
              y: 3929,
            },
            {
              x: moment().subtract(9, 'months').day(16).toDate(),
              y: 3846,
            },
            {
              x: moment().subtract(9, 'months').day(19).toDate(),
              y: 3771,
            },
            {
              x: moment().subtract(9, 'months').day(22).toDate(),
              y: 4015,
            },
            {
              x: moment().subtract(9, 'months').day(25).toDate(),
              y: 3589,
            },
            {
              x: moment().subtract(9, 'months').day(28).toDate(),
              y: 3150,
            },
            {
              x: moment().subtract(8, 'months').day(1).toDate(),
              y: 3050,
            },
            {
              x: moment().subtract(8, 'months').day(4).toDate(),
              y: 2574,
            },
            {
              x: moment().subtract(8, 'months').day(7).toDate(),
              y: 2823,
            },
            {
              x: moment().subtract(8, 'months').day(10).toDate(),
              y: 2848,
            },
            {
              x: moment().subtract(8, 'months').day(13).toDate(),
              y: 3000,
            },
            {
              x: moment().subtract(8, 'months').day(16).toDate(),
              y: 3216,
            },
            {
              x: moment().subtract(8, 'months').day(19).toDate(),
              y: 3299,
            },
            {
              x: moment().subtract(8, 'months').day(22).toDate(),
              y: 3768,
            },
            {
              x: moment().subtract(8, 'months').day(25).toDate(),
              y: 3524,
            },
            {
              x: moment().subtract(8, 'months').day(28).toDate(),
              y: 3918,
            },
            {
              x: moment().subtract(7, 'months').day(1).toDate(),
              y: 4145,
            },
            {
              x: moment().subtract(7, 'months').day(4).toDate(),
              y: 4378,
            },
            {
              x: moment().subtract(7, 'months').day(7).toDate(),
              y: 3941,
            },
            {
              x: moment().subtract(7, 'months').day(10).toDate(),
              y: 3932,
            },
            {
              x: moment().subtract(7, 'months').day(13).toDate(),
              y: 4380,
            },
            {
              x: moment().subtract(7, 'months').day(16).toDate(),
              y: 4243,
            },
            {
              x: moment().subtract(7, 'months').day(19).toDate(),
              y: 4367,
            },
            {
              x: moment().subtract(7, 'months').day(22).toDate(),
              y: 3879,
            },
            {
              x: moment().subtract(7, 'months').day(25).toDate(),
              y: 4357,
            },
            {
              x: moment().subtract(7, 'months').day(28).toDate(),
              y: 4181,
            },
            {
              x: moment().subtract(6, 'months').day(1).toDate(),
              y: 4619,
            },
            {
              x: moment().subtract(6, 'months').day(4).toDate(),
              y: 4769,
            },
            {
              x: moment().subtract(6, 'months').day(7).toDate(),
              y: 4901,
            },
            {
              x: moment().subtract(6, 'months').day(10).toDate(),
              y: 4640,
            },
            {
              x: moment().subtract(6, 'months').day(13).toDate(),
              y: 5128,
            },
            {
              x: moment().subtract(6, 'months').day(16).toDate(),
              y: 5015,
            },
            {
              x: moment().subtract(6, 'months').day(19).toDate(),
              y: 5360,
            },
            {
              x: moment().subtract(6, 'months').day(22).toDate(),
              y: 5608,
            },
            {
              x: moment().subtract(6, 'months').day(25).toDate(),
              y: 5272,
            },
            {
              x: moment().subtract(6, 'months').day(28).toDate(),
              y: 5660,
            },
            {
              x: moment().subtract(5, 'months').day(1).toDate(),
              y: 5836,
            },
            {
              x: moment().subtract(5, 'months').day(4).toDate(),
              y: 5659,
            },
            {
              x: moment().subtract(5, 'months').day(7).toDate(),
              y: 5575,
            },
            {
              x: moment().subtract(5, 'months').day(10).toDate(),
              y: 5474,
            },
            {
              x: moment().subtract(5, 'months').day(13).toDate(),
              y: 5427,
            },
            {
              x: moment().subtract(5, 'months').day(16).toDate(),
              y: 5865,
            },
            {
              x: moment().subtract(5, 'months').day(19).toDate(),
              y: 5700,
            },
            {
              x: moment().subtract(5, 'months').day(22).toDate(),
              y: 6052,
            },
            {
              x: moment().subtract(5, 'months').day(25).toDate(),
              y: 5760,
            },
            {
              x: moment().subtract(5, 'months').day(28).toDate(),
              y: 5648,
            },
            {
              x: moment().subtract(4, 'months').day(1).toDate(),
              y: 5435,
            },
            {
              x: moment().subtract(4, 'months').day(4).toDate(),
              y: 5239,
            },
            {
              x: moment().subtract(4, 'months').day(7).toDate(),
              y: 5452,
            },
            {
              x: moment().subtract(4, 'months').day(10).toDate(),
              y: 5416,
            },
            {
              x: moment().subtract(4, 'months').day(13).toDate(),
              y: 5195,
            },
            {
              x: moment().subtract(4, 'months').day(16).toDate(),
              y: 5119,
            },
            {
              x: moment().subtract(4, 'months').day(19).toDate(),
              y: 4635,
            },
            {
              x: moment().subtract(4, 'months').day(22).toDate(),
              y: 4833,
            },
            {
              x: moment().subtract(4, 'months').day(25).toDate(),
              y: 4584,
            },
            {
              x: moment().subtract(4, 'months').day(28).toDate(),
              y: 4822,
            },
            {
              x: moment().subtract(3, 'months').day(1).toDate(),
              y: 4582,
            },
            {
              x: moment().subtract(3, 'months').day(4).toDate(),
              y: 4348,
            },
            {
              x: moment().subtract(3, 'months').day(7).toDate(),
              y: 4132,
            },
            {
              x: moment().subtract(3, 'months').day(10).toDate(),
              y: 4099,
            },
            {
              x: moment().subtract(3, 'months').day(13).toDate(),
              y: 3849,
            },
            {
              x: moment().subtract(3, 'months').day(16).toDate(),
              y: 4010,
            },
            {
              x: moment().subtract(3, 'months').day(19).toDate(),
              y: 4486,
            },
            {
              x: moment().subtract(3, 'months').day(22).toDate(),
              y: 4403,
            },
            {
              x: moment().subtract(3, 'months').day(25).toDate(),
              y: 4141,
            },
            {
              x: moment().subtract(3, 'months').day(28).toDate(),
              y: 3780,
            },
            {
              x: moment().subtract(2, 'months').day(1).toDate(),
              y: 3524,
            },
            {
              x: moment().subtract(2, 'months').day(4).toDate(),
              y: 3212,
            },
            {
              x: moment().subtract(2, 'months').day(7).toDate(),
              y: 3568,
            },
            {
              x: moment().subtract(2, 'months').day(10).toDate(),
              y: 3800,
            },
            {
              x: moment().subtract(2, 'months').day(13).toDate(),
              y: 3796,
            },
            {
              x: moment().subtract(2, 'months').day(16).toDate(),
              y: 3870,
            },
            {
              x: moment().subtract(2, 'months').day(19).toDate(),
              y: 3745,
            },
            {
              x: moment().subtract(2, 'months').day(22).toDate(),
              y: 3751,
            },
            {
              x: moment().subtract(2, 'months').day(25).toDate(),
              y: 3310,
            },
            {
              x: moment().subtract(2, 'months').day(28).toDate(),
              y: 3509,
            },
            {
              x: moment().subtract(1, 'months').day(1).toDate(),
              y: 3187,
            },
            {
              x: moment().subtract(1, 'months').day(4).toDate(),
              y: 2918,
            },
            {
              x: moment().subtract(1, 'months').day(7).toDate(),
              y: 3191,
            },
            {
              x: moment().subtract(1, 'months').day(10).toDate(),
              y: 3437,
            },
            {
              x: moment().subtract(1, 'months').day(13).toDate(),
              y: 3291,
            },
            {
              x: moment().subtract(1, 'months').day(16).toDate(),
              y: 3317,
            },
            {
              x: moment().subtract(1, 'months').day(19).toDate(),
              y: 3716,
            },
            {
              x: moment().subtract(1, 'months').day(22).toDate(),
              y: 3260,
            },
            {
              x: moment().subtract(1, 'months').day(25).toDate(),
              y: 3694,
            },
            {
              x: moment().subtract(1, 'months').day(28).toDate(),
              y: 3598,
            },
          ],
        },
      ],
      'last-year': [
        {
          name: 'Visitors',
          data: [
            {
              x: moment().subtract(12, 'months').day(1).toDate(),
              y: 2021,
            },
            {
              x: moment().subtract(12, 'months').day(4).toDate(),
              y: 1749,
            },
            {
              x: moment().subtract(12, 'months').day(7).toDate(),
              y: 1654,
            },
            {
              x: moment().subtract(12, 'months').day(10).toDate(),
              y: 1900,
            },
            {
              x: moment().subtract(12, 'months').day(13).toDate(),
              y: 1647,
            },
            {
              x: moment().subtract(12, 'months').day(16).toDate(),
              y: 1315,
            },
            {
              x: moment().subtract(12, 'months').day(19).toDate(),
              y: 1807,
            },
            {
              x: moment().subtract(12, 'months').day(22).toDate(),
              y: 1793,
            },
            {
              x: moment().subtract(12, 'months').day(25).toDate(),
              y: 1892,
            },
            {
              x: moment().subtract(12, 'months').day(28).toDate(),
              y: 1846,
            },
            {
              x: moment().subtract(11, 'months').day(1).toDate(),
              y: 1804,
            },
            {
              x: moment().subtract(11, 'months').day(4).toDate(),
              y: 1778,
            },
            {
              x: moment().subtract(11, 'months').day(7).toDate(),
              y: 2015,
            },
            {
              x: moment().subtract(11, 'months').day(10).toDate(),
              y: 1892,
            },
            {
              x: moment().subtract(11, 'months').day(13).toDate(),
              y: 1708,
            },
            {
              x: moment().subtract(11, 'months').day(16).toDate(),
              y: 1711,
            },
            {
              x: moment().subtract(11, 'months').day(19).toDate(),
              y: 1570,
            },
            {
              x: moment().subtract(11, 'months').day(22).toDate(),
              y: 1507,
            },
            {
              x: moment().subtract(11, 'months').day(25).toDate(),
              y: 1451,
            },
            {
              x: moment().subtract(11, 'months').day(28).toDate(),
              y: 1522,
            },
            {
              x: moment().subtract(10, 'months').day(1).toDate(),
              y: 1977,
            },
            {
              x: moment().subtract(10, 'months').day(4).toDate(),
              y: 2367,
            },
            {
              x: moment().subtract(10, 'months').day(7).toDate(),
              y: 2798,
            },
            {
              x: moment().subtract(10, 'months').day(10).toDate(),
              y: 3080,
            },
            {
              x: moment().subtract(10, 'months').day(13).toDate(),
              y: 2856,
            },
            {
              x: moment().subtract(10, 'months').day(16).toDate(),
              y: 2745,
            },
            {
              x: moment().subtract(10, 'months').day(19).toDate(),
              y: 2750,
            },
            {
              x: moment().subtract(10, 'months').day(22).toDate(),
              y: 2728,
            },
            {
              x: moment().subtract(10, 'months').day(25).toDate(),
              y: 2436,
            },
            {
              x: moment().subtract(10, 'months').day(28).toDate(),
              y: 2289,
            },
            {
              x: moment().subtract(9, 'months').day(1).toDate(),
              y: 2804,
            },
            {
              x: moment().subtract(9, 'months').day(4).toDate(),
              y: 2777,
            },
            {
              x: moment().subtract(9, 'months').day(7).toDate(),
              y: 3024,
            },
            {
              x: moment().subtract(9, 'months').day(10).toDate(),
              y: 2657,
            },
            {
              x: moment().subtract(9, 'months').day(13).toDate(),
              y: 2218,
            },
            {
              x: moment().subtract(9, 'months').day(16).toDate(),
              y: 1964,
            },
            {
              x: moment().subtract(9, 'months').day(19).toDate(),
              y: 1674,
            },
            {
              x: moment().subtract(9, 'months').day(22).toDate(),
              y: 1721,
            },
            {
              x: moment().subtract(9, 'months').day(25).toDate(),
              y: 2005,
            },
            {
              x: moment().subtract(9, 'months').day(28).toDate(),
              y: 1613,
            },
            {
              x: moment().subtract(8, 'months').day(1).toDate(),
              y: 1071,
            },
            {
              x: moment().subtract(8, 'months').day(4).toDate(),
              y: 1079,
            },
            {
              x: moment().subtract(8, 'months').day(7).toDate(),
              y: 1133,
            },
            {
              x: moment().subtract(8, 'months').day(10).toDate(),
              y: 1536,
            },
            {
              x: moment().subtract(8, 'months').day(13).toDate(),
              y: 2016,
            },
            {
              x: moment().subtract(8, 'months').day(16).toDate(),
              y: 2256,
            },
            {
              x: moment().subtract(8, 'months').day(19).toDate(),
              y: 1934,
            },
            {
              x: moment().subtract(8, 'months').day(22).toDate(),
              y: 1832,
            },
            {
              x: moment().subtract(8, 'months').day(25).toDate(),
              y: 2075,
            },
            {
              x: moment().subtract(8, 'months').day(28).toDate(),
              y: 1709,
            },
            {
              x: moment().subtract(7, 'months').day(1).toDate(),
              y: 1831,
            },
            {
              x: moment().subtract(7, 'months').day(4).toDate(),
              y: 1434,
            },
            {
              x: moment().subtract(7, 'months').day(7).toDate(),
              y: 1293,
            },
            {
              x: moment().subtract(7, 'months').day(10).toDate(),
              y: 1064,
            },
            {
              x: moment().subtract(7, 'months').day(13).toDate(),
              y: 1080,
            },
            {
              x: moment().subtract(7, 'months').day(16).toDate(),
              y: 1032,
            },
            {
              x: moment().subtract(7, 'months').day(19).toDate(),
              y: 1280,
            },
            {
              x: moment().subtract(7, 'months').day(22).toDate(),
              y: 1344,
            },
            {
              x: moment().subtract(7, 'months').day(25).toDate(),
              y: 1835,
            },
            {
              x: moment().subtract(7, 'months').day(28).toDate(),
              y: 2287,
            },
            {
              x: moment().subtract(6, 'months').day(1).toDate(),
              y: 2692,
            },
            {
              x: moment().subtract(6, 'months').day(4).toDate(),
              y: 2250,
            },
            {
              x: moment().subtract(6, 'months').day(7).toDate(),
              y: 1814,
            },
            {
              x: moment().subtract(6, 'months').day(10).toDate(),
              y: 1906,
            },
            {
              x: moment().subtract(6, 'months').day(13).toDate(),
              y: 1973,
            },
            {
              x: moment().subtract(6, 'months').day(16).toDate(),
              y: 1882,
            },
            {
              x: moment().subtract(6, 'months').day(19).toDate(),
              y: 2333,
            },
            {
              x: moment().subtract(6, 'months').day(22).toDate(),
              y: 2048,
            },
            {
              x: moment().subtract(6, 'months').day(25).toDate(),
              y: 2547,
            },
            {
              x: moment().subtract(6, 'months').day(28).toDate(),
              y: 2884,
            },
            {
              x: moment().subtract(5, 'months').day(1).toDate(),
              y: 2771,
            },
            {
              x: moment().subtract(5, 'months').day(4).toDate(),
              y: 2522,
            },
            {
              x: moment().subtract(5, 'months').day(7).toDate(),
              y: 2543,
            },
            {
              x: moment().subtract(5, 'months').day(10).toDate(),
              y: 2413,
            },
            {
              x: moment().subtract(5, 'months').day(13).toDate(),
              y: 2002,
            },
            {
              x: moment().subtract(5, 'months').day(16).toDate(),
              y: 1838,
            },
            {
              x: moment().subtract(5, 'months').day(19).toDate(),
              y: 1830,
            },
            {
              x: moment().subtract(5, 'months').day(22).toDate(),
              y: 1872,
            },
            {
              x: moment().subtract(5, 'months').day(25).toDate(),
              y: 2246,
            },
            {
              x: moment().subtract(5, 'months').day(28).toDate(),
              y: 2171,
            },
            {
              x: moment().subtract(4, 'months').day(1).toDate(),
              y: 2988,
            },
            {
              x: moment().subtract(4, 'months').day(4).toDate(),
              y: 2694,
            },
            {
              x: moment().subtract(4, 'months').day(7).toDate(),
              y: 2806,
            },
            {
              x: moment().subtract(4, 'months').day(10).toDate(),
              y: 3040,
            },
            {
              x: moment().subtract(4, 'months').day(13).toDate(),
              y: 2898,
            },
            {
              x: moment().subtract(4, 'months').day(16).toDate(),
              y: 3013,
            },
            {
              x: moment().subtract(4, 'months').day(19).toDate(),
              y: 2760,
            },
            {
              x: moment().subtract(4, 'months').day(22).toDate(),
              y: 3021,
            },
            {
              x: moment().subtract(4, 'months').day(25).toDate(),
              y: 2688,
            },
            {
              x: moment().subtract(4, 'months').day(28).toDate(),
              y: 2572,
            },
            {
              x: moment().subtract(3, 'months').day(1).toDate(),
              y: 2789,
            },
            {
              x: moment().subtract(3, 'months').day(4).toDate(),
              y: 3069,
            },
            {
              x: moment().subtract(3, 'months').day(7).toDate(),
              y: 3142,
            },
            {
              x: moment().subtract(3, 'months').day(10).toDate(),
              y: 3614,
            },
            {
              x: moment().subtract(3, 'months').day(13).toDate(),
              y: 3202,
            },
            {
              x: moment().subtract(3, 'months').day(16).toDate(),
              y: 2730,
            },
            {
              x: moment().subtract(3, 'months').day(19).toDate(),
              y: 2951,
            },
            {
              x: moment().subtract(3, 'months').day(22).toDate(),
              y: 3267,
            },
            {
              x: moment().subtract(3, 'months').day(25).toDate(),
              y: 2882,
            },
            {
              x: moment().subtract(3, 'months').day(28).toDate(),
              y: 2885,
            },
            {
              x: moment().subtract(2, 'months').day(1).toDate(),
              y: 2915,
            },
            {
              x: moment().subtract(2, 'months').day(4).toDate(),
              y: 2790,
            },
            {
              x: moment().subtract(2, 'months').day(7).toDate(),
              y: 3071,
            },
            {
              x: moment().subtract(2, 'months').day(10).toDate(),
              y: 2802,
            },
            {
              x: moment().subtract(2, 'months').day(13).toDate(),
              y: 2382,
            },
            {
              x: moment().subtract(2, 'months').day(16).toDate(),
              y: 1883,
            },
            {
              x: moment().subtract(2, 'months').day(19).toDate(),
              y: 1448,
            },
            {
              x: moment().subtract(2, 'months').day(22).toDate(),
              y: 1176,
            },
            {
              x: moment().subtract(2, 'months').day(25).toDate(),
              y: 1275,
            },
            {
              x: moment().subtract(2, 'months').day(28).toDate(),
              y: 1136,
            },
            {
              x: moment().subtract(1, 'months').day(1).toDate(),
              y: 1160,
            },
            {
              x: moment().subtract(1, 'months').day(4).toDate(),
              y: 1524,
            },
            {
              x: moment().subtract(1, 'months').day(7).toDate(),
              y: 1305,
            },
            {
              x: moment().subtract(1, 'months').day(10).toDate(),
              y: 1725,
            },
            {
              x: moment().subtract(1, 'months').day(13).toDate(),
              y: 1850,
            },
            {
              x: moment().subtract(1, 'months').day(16).toDate(),
              y: 2304,
            },
            {
              x: moment().subtract(1, 'months').day(19).toDate(),
              y: 2187,
            },
            {
              x: moment().subtract(1, 'months').day(22).toDate(),
              y: 2597,
            },
            {
              x: moment().subtract(1, 'months').day(25).toDate(),
              y: 2246,
            },
            {
              x: moment().subtract(1, 'months').day(28).toDate(),
              y: 1767,
            },
          ],
        },
      ],
    },
  },
  conversions: {
    amount: 0,
    labels: [
      moment().subtract(47, 'days').format('DD MMM') + ' - ' + moment().subtract(40, 'days').format('DD MMM'),
      moment().subtract(39, 'days').format('DD MMM') + ' - ' + moment().subtract(32, 'days').format('DD MMM'),
      moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
      moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
      moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
      moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM'),
    ],
    series: [
      {
        name: 'Conversions',
        data: [0, 0, 0, 0, 0, 0],
      },
    ],
  },
  impressions: {
    amount: 0,
    labels: [
      moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
      moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
      moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
      moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM'),
    ],
    series: [
      {
        name: 'Impressions',
        data: [0, 0, 0, 0],
      },
    ],
  },
  visits: {
    amount: 0,
    labels: [
      moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
      moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
      moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
      moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM'),
    ],
    series: [
      {
        name: 'Visits',
        data: [0, 0, 0, 0],
      },
    ],
  },
  visitorsVsPageViews: {
    overallScore: 472,
    averageRatio: 45,
    predictedRatio: 55,
    series: [
      {
        name: 'Page Views',
        data: [
          {
            x: moment().subtract(65, 'days').toDate(),
            y: 4769,
          },
          {
            x: moment().subtract(64, 'days').toDate(),
            y: 4901,
          },
          {
            x: moment().subtract(63, 'days').toDate(),
            y: 4640,
          },
          {
            x: moment().subtract(62, 'days').toDate(),
            y: 5128,
          },
          {
            x: moment().subtract(61, 'days').toDate(),
            y: 5015,
          },
          {
            x: moment().subtract(60, 'days').toDate(),
            y: 5360,
          },
          {
            x: moment().subtract(59, 'days').toDate(),
            y: 5608,
          },
          {
            x: moment().subtract(58, 'days').toDate(),
            y: 5272,
          },
          {
            x: moment().subtract(57, 'days').toDate(),
            y: 5660,
          },
          {
            x: moment().subtract(56, 'days').toDate(),
            y: 6026,
          },
          {
            x: moment().subtract(55, 'days').toDate(),
            y: 5836,
          },
          {
            x: moment().subtract(54, 'days').toDate(),
            y: 5659,
          },
          {
            x: moment().subtract(53, 'days').toDate(),
            y: 5575,
          },
          {
            x: moment().subtract(52, 'days').toDate(),
            y: 5474,
          },
          {
            x: moment().subtract(51, 'days').toDate(),
            y: 5427,
          },
          {
            x: moment().subtract(50, 'days').toDate(),
            y: 5865,
          },
          {
            x: moment().subtract(49, 'days').toDate(),
            y: 5700,
          },
          {
            x: moment().subtract(48, 'days').toDate(),
            y: 6052,
          },
          {
            x: moment().subtract(47, 'days').toDate(),
            y: 5760,
          },
          {
            x: moment().subtract(46, 'days').toDate(),
            y: 5648,
          },
          {
            x: moment().subtract(45, 'days').toDate(),
            y: 5510,
          },
          {
            x: moment().subtract(44, 'days').toDate(),
            y: 5435,
          },
          {
            x: moment().subtract(43, 'days').toDate(),
            y: 5239,
          },
          {
            x: moment().subtract(42, 'days').toDate(),
            y: 5452,
          },
          {
            x: moment().subtract(41, 'days').toDate(),
            y: 5416,
          },
          {
            x: moment().subtract(40, 'days').toDate(),
            y: 5195,
          },
          {
            x: moment().subtract(39, 'days').toDate(),
            y: 5119,
          },
          {
            x: moment().subtract(38, 'days').toDate(),
            y: 4635,
          },
          {
            x: moment().subtract(37, 'days').toDate(),
            y: 4833,
          },
          {
            x: moment().subtract(36, 'days').toDate(),
            y: 4584,
          },
          {
            x: moment().subtract(35, 'days').toDate(),
            y: 4822,
          },
          {
            x: moment().subtract(34, 'days').toDate(),
            y: 4330,
          },
          {
            x: moment().subtract(33, 'days').toDate(),
            y: 4582,
          },
          {
            x: moment().subtract(32, 'days').toDate(),
            y: 4348,
          },
          {
            x: moment().subtract(31, 'days').toDate(),
            y: 4132,
          },
          {
            x: moment().subtract(30, 'days').toDate(),
            y: 4099,
          },
          {
            x: moment().subtract(29, 'days').toDate(),
            y: 3849,
          },
          {
            x: moment().subtract(28, 'days').toDate(),
            y: 4010,
          },
          {
            x: moment().subtract(27, 'days').toDate(),
            y: 4486,
          },
          {
            x: moment().subtract(26, 'days').toDate(),
            y: 4403,
          },
          {
            x: moment().subtract(25, 'days').toDate(),
            y: 4141,
          },
          {
            x: moment().subtract(24, 'days').toDate(),
            y: 3780,
          },
          {
            x: moment().subtract(23, 'days').toDate(),
            y: 3929,
          },
          {
            x: moment().subtract(22, 'days').toDate(),
            y: 3524,
          },
          {
            x: moment().subtract(21, 'days').toDate(),
            y: 3212,
          },
          {
            x: moment().subtract(20, 'days').toDate(),
            y: 3568,
          },
          {
            x: moment().subtract(19, 'days').toDate(),
            y: 3800,
          },
          {
            x: moment().subtract(18, 'days').toDate(),
            y: 3796,
          },
          {
            x: moment().subtract(17, 'days').toDate(),
            y: 3870,
          },
          {
            x: moment().subtract(16, 'days').toDate(),
            y: 3745,
          },
          {
            x: moment().subtract(15, 'days').toDate(),
            y: 3751,
          },
          {
            x: moment().subtract(14, 'days').toDate(),
            y: 3310,
          },
          {
            x: moment().subtract(13, 'days').toDate(),
            y: 3509,
          },
          {
            x: moment().subtract(12, 'days').toDate(),
            y: 3311,
          },
          {
            x: moment().subtract(11, 'days').toDate(),
            y: 3187,
          },
          {
            x: moment().subtract(10, 'days').toDate(),
            y: 2918,
          },
          {
            x: moment().subtract(9, 'days').toDate(),
            y: 3191,
          },
          {
            x: moment().subtract(8, 'days').toDate(),
            y: 3437,
          },
          {
            x: moment().subtract(7, 'days').toDate(),
            y: 3291,
          },
          {
            x: moment().subtract(6, 'days').toDate(),
            y: 3317,
          },
          {
            x: moment().subtract(5, 'days').toDate(),
            y: 3716,
          },
          {
            x: moment().subtract(4, 'days').toDate(),
            y: 3260,
          },
          {
            x: moment().subtract(3, 'days').toDate(),
            y: 3694,
          },
          {
            x: moment().subtract(2, 'days').toDate(),
            y: 3598,
          },
          {
            x: moment().subtract(1, 'days').toDate(),
            y: 3812,
          },
        ],
      },
      {
        name: 'Visitors',
        data: [
          {
            x: moment().subtract(65, 'days').toDate(),
            y: 1654,
          },
          {
            x: moment().subtract(64, 'days').toDate(),
            y: 1900,
          },
          {
            x: moment().subtract(63, 'days').toDate(),
            y: 1647,
          },
          {
            x: moment().subtract(62, 'days').toDate(),
            y: 1315,
          },
          {
            x: moment().subtract(61, 'days').toDate(),
            y: 1807,
          },
          {
            x: moment().subtract(60, 'days').toDate(),
            y: 1793,
          },
          {
            x: moment().subtract(59, 'days').toDate(),
            y: 1892,
          },
          {
            x: moment().subtract(58, 'days').toDate(),
            y: 1846,
          },
          {
            x: moment().subtract(57, 'days').toDate(),
            y: 1966,
          },
          {
            x: moment().subtract(56, 'days').toDate(),
            y: 1804,
          },
          {
            x: moment().subtract(55, 'days').toDate(),
            y: 1778,
          },
          {
            x: moment().subtract(54, 'days').toDate(),
            y: 2015,
          },
          {
            x: moment().subtract(53, 'days').toDate(),
            y: 1892,
          },
          {
            x: moment().subtract(52, 'days').toDate(),
            y: 1708,
          },
          {
            x: moment().subtract(51, 'days').toDate(),
            y: 1711,
          },
          {
            x: moment().subtract(50, 'days').toDate(),
            y: 1570,
          },
          {
            x: moment().subtract(49, 'days').toDate(),
            y: 1507,
          },
          {
            x: moment().subtract(48, 'days').toDate(),
            y: 1451,
          },
          {
            x: moment().subtract(47, 'days').toDate(),
            y: 1522,
          },
          {
            x: moment().subtract(46, 'days').toDate(),
            y: 1801,
          },
          {
            x: moment().subtract(45, 'days').toDate(),
            y: 1977,
          },
          {
            x: moment().subtract(44, 'days').toDate(),
            y: 2367,
          },
          {
            x: moment().subtract(43, 'days').toDate(),
            y: 2798,
          },
          {
            x: moment().subtract(42, 'days').toDate(),
            y: 3080,
          },
          {
            x: moment().subtract(41, 'days').toDate(),
            y: 2856,
          },
          {
            x: moment().subtract(40, 'days').toDate(),
            y: 2745,
          },
          {
            x: moment().subtract(39, 'days').toDate(),
            y: 2750,
          },
          {
            x: moment().subtract(38, 'days').toDate(),
            y: 2728,
          },
          {
            x: moment().subtract(37, 'days').toDate(),
            y: 2436,
          },
          {
            x: moment().subtract(36, 'days').toDate(),
            y: 2289,
          },
          {
            x: moment().subtract(35, 'days').toDate(),
            y: 2657,
          },
          {
            x: moment().subtract(34, 'days').toDate(),
            y: 2804,
          },
          {
            x: moment().subtract(33, 'days').toDate(),
            y: 2777,
          },
          {
            x: moment().subtract(32, 'days').toDate(),
            y: 3024,
          },
          {
            x: moment().subtract(31, 'days').toDate(),
            y: 2657,
          },
          {
            x: moment().subtract(30, 'days').toDate(),
            y: 2218,
          },
          {
            x: moment().subtract(29, 'days').toDate(),
            y: 1964,
          },
          {
            x: moment().subtract(28, 'days').toDate(),
            y: 1674,
          },
          {
            x: moment().subtract(27, 'days').toDate(),
            y: 1721,
          },
          {
            x: moment().subtract(26, 'days').toDate(),
            y: 2005,
          },
          {
            x: moment().subtract(25, 'days').toDate(),
            y: 1613,
          },
          {
            x: moment().subtract(24, 'days').toDate(),
            y: 1295,
          },
          {
            x: moment().subtract(23, 'days').toDate(),
            y: 1071,
          },
          {
            x: moment().subtract(22, 'days').toDate(),
            y: 799,
          },
          {
            x: moment().subtract(21, 'days').toDate(),
            y: 1133,
          },
          {
            x: moment().subtract(20, 'days').toDate(),
            y: 1536,
          },
          {
            x: moment().subtract(19, 'days').toDate(),
            y: 2016,
          },
          {
            x: moment().subtract(18, 'days').toDate(),
            y: 2256,
          },
          {
            x: moment().subtract(17, 'days').toDate(),
            y: 1934,
          },
          {
            x: moment().subtract(16, 'days').toDate(),
            y: 1832,
          },
          {
            x: moment().subtract(15, 'days').toDate(),
            y: 2075,
          },
          {
            x: moment().subtract(14, 'days').toDate(),
            y: 1709,
          },
          {
            x: moment().subtract(13, 'days').toDate(),
            y: 1932,
          },
          {
            x: moment().subtract(12, 'days').toDate(),
            y: 1831,
          },
          {
            x: moment().subtract(11, 'days').toDate(),
            y: 1434,
          },
          {
            x: moment().subtract(10, 'days').toDate(),
            y: 993,
          },
          {
            x: moment().subtract(9, 'days').toDate(),
            y: 1064,
          },
          {
            x: moment().subtract(8, 'days').toDate(),
            y: 618,
          },
          {
            x: moment().subtract(7, 'days').toDate(),
            y: 1032,
          },
          {
            x: moment().subtract(6, 'days').toDate(),
            y: 1280,
          },
          {
            x: moment().subtract(5, 'days').toDate(),
            y: 1344,
          },
          {
            x: moment().subtract(4, 'days').toDate(),
            y: 1835,
          },
          {
            x: moment().subtract(3, 'days').toDate(),
            y: 2287,
          },
          {
            x: moment().subtract(2, 'days').toDate(),
            y: 2226,
          },
          {
            x: moment().subtract(1, 'days').toDate(),
            y: 2692,
          },
        ],
      },
    ],
  },
  newVsReturning: {
    uniqueVisitors: 46085,
    series: [80, 20],
    labels: ['New', 'Returning'],
  },
  gender: {
    uniqueVisitors: 46085,
    series: [55, 45],
    labels: ['Male', 'Female'],
  },
  age: {
    uniqueVisitors: 46085,
    series: [35, 65],
    labels: ['Under 30', 'Over 30'],
  },
  language: {
    uniqueVisitors: 46085,
    series: [25, 75],
    labels: ['English', 'Other'],
  },
};
