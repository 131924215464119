import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { IntegrationProfilesService, OfflineStoresService, OnboardingService, UserInviteService, UserService } from 'app/api/generated';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorMessageHiderInterceptor } from 'app/core/interceptors/errorMessageHider.interceptor';
import { LocationsService } from 'app/api/generated';
import { OpenAppModule } from '@openapp';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { WalletsService } from './modules/wallets/data-access/wallets.service';
import { InvoicesService } from './modules/invoices/data-access/invoices.service';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  declarations: [AppComponent, ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MatSnackBarModule,
    OpenAppModule,
  ],
  providers: [
    OnboardingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageHiderInterceptor,
      multi: true,
    },
    LocationsService,
    IntegrationProfilesService,
    UserInviteService,
    UserService,
    WalletsService,
    OfflineStoresService,
    InvoicesService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
