import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Notification {
  message: string;
  action: string;
  color: string,
  duration: number;
}

@Injectable({
  providedIn: 'root'
})
export class OaNotificationsService {
  private notifications = new Subject<Notification>();

  get notifications$() {
    return this.notifications.asObservable();
  }

  push(message: string, action = '', color = 'success', duration = 3000) {
    this.notifications.next({
      message,
      action,
      color,
      duration
    });
  }
}
