export enum ConfirmationDialogResult {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

export enum CRUDMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ = 'READ',
}

export enum MerchantDeliveryOptionsEnum {
  INPOST_APM = 'INPOST_APM',
  ORLEN_APM = 'ORLEN_APM',
  POCZTA_POLSKA_APM = 'POCZTA_POLSKA_APM',
  DHL_PICKUP = 'DHL_PICKUP',
  DPD_PICKUP = 'DPD_PICKUP',
  INSTORE_PICKUP = 'INSTORE_PICKUP',
  DHL_COURIER = 'DHL_COURIER',
  DPD_COURIER = 'DPD_COURIER',
  FEDEX_COURIER = 'FEDEX_COURIER',
  GEIS_COURIER = 'GEIS_COURIER',
  GLS_COURIER = 'GLS_COURIER',
  INPOST_COURIER = 'INPOST_COURIER',
  POCZTEX_COURIER = 'POCZTEX_COURIER',
  UPS_COURIER = 'UPS_COURIER',
  ELECTRONIC = 'ELECTRONIC',
}

export enum OrderStatusEnum {
  NEW = 'NEW',
  ORDERED = 'ORDERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  CANCELLED_MERCHANT = 'CANCELLED_MERCHANT',
  CANCELLED_TECHNICAL = 'CANCELLED_TECHNICAL',
}

export enum MerchantUserPrivilegeEnum {
  MERCHANT_ADMIN = 'MERCHANT_ADMIN',
  MERCHANT_ACCOUNTING = 'MERCHANT_ACCOUNTING',
  MERCHANT_ANALYTICS = 'MERCHANT_ANALYTICS',
  MERCHANT_IT = 'MERCHANT_IT',
  MERCHANT_CUSTOMER_SERVICE = 'MERCHANT_CUSTOMER_SERVICE',
}

export enum WalletSubtype {
  SETTLEMENT = "SETTLEMENT",
  TOPUP = "TOPUP", 
  REFUND = "REFUND", 
  CASHOUT = "CASHOUT", 
  CHECKOUT = "CHECKOUT", 
  DIRECTDEBIT = "DIRECTDEBIT", 
  P2P = "P2P", 
  P2S = "P2S", 
  S2P = "S2P",
}

export enum WalletType {
  CREDIT = "CREDIT", 
  DEBIT = "DEBIT", 
}

export enum WalletCounterparty {
  BANK = "BANK", 
  CARD = "CARD", 
  WALLET = "WALLET", 
  MERCHANT = "MERCHANT", 
  OPENAPP = "OPENAPP", 
}

export enum WalletExecutedby {
  BANK = "BANK", 
  USER = "USER", 
  MERCHANT = "MERCHANT", 
  OPENAPP = "OPENAPP", 
}