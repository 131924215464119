import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatPipe',
  pure: true,
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: { amount: 0; currency: string }): string {
    return `${value.amount.toFixed(2)}`;
  }
}
