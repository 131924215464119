import { Injectable } from '@angular/core';
import { OaNotificationsService } from '@openapp';
import { IntegrationProfilesService, QuerySpec, UserService } from 'app/api/generated';
import { WalletsQuery } from './wallets.query';
import { WalletsStore } from './wallets.store';
import { Observable } from 'rxjs';
import { IMerchantWalletDetails } from 'app/shared/interfaces/wallet-details.interface';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  last: string = undefined;

  constructor(
    private readonly store: WalletsStore,
    private readonly backend: IntegrationProfilesService,
    private readonly query: WalletsQuery,
    private readonly userBackend: UserService,

    private readonly notifications: OaNotificationsService
  ) {}

  private async execute(action: () => Promise<void>, onError: (err) => void | Promise<void>) {
    this.store.setLoading(true);
    try {
      await action().finally(() => this.store.setLoading(false));
    } catch (e) {
      await onError(e);
    }
  }

  async getAll(profileId: string, requestBody: QuerySpec, page: number) {
    await this.execute(
      async () => {
        const data = await this.backend.searchWalletTrxForIntegrationProfile(profileId, requestBody, page * 20, 20).toPromise();
        if (page > 0) {
          this.store.add(data);
        } else {
          this.store.set(data);
        }
        if (data.length < 20) {
          this.store.update({last: undefined});
        } else {
          this.store.update({last: 'isnotlastelement'});
        }
        this.store.setLoading(false);
      },
      (error) => {
        this.store.setError(error);
        this.store.setLoading(false);
      }
    );
  }

  /**
   * Retrieves the wallet details for a specific integration profile ID.
   * @param integrationProfileId The ID of the integration profile for which to retrieve wallet details.
   * @returns An Observable that emits the wallet details for the specified integration profile ID.
   */
  getWalletDetails(integrationProfileId: string): Observable<IMerchantWalletDetails> {
    return this.backend.getWalletDetails(integrationProfileId);
  }

  resetStore() {
    this.last = undefined;
    this.store.set([]);
    this.store.reset();
  }

  async loadMore() {}
}
