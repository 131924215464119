import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ErrorMessageHiderInterceptor implements HttpInterceptor {
  constructor(private snackbar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // TODO: discuss this "solution" cause it's just a bug generator
    // todo intention is to allow "polling-like" requests to bypass this behaviour
    if (!req.headers.has('AUTOMATED')) {
      // this.snackbar.dismiss();
    }

    return next.handle(req);
  }
}
