import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OaNotificationsService } from '../oa-notifications.service';

@Component({
  selector: 'oa-notifications',
  template: '',
})
export class OaNotificationsComponent implements OnInit, OnDestroy {
  private kill$ = new Subject();

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly notifications: OaNotificationsService,
  ) {}

  ngOnInit(): void {
    this.notifications.notifications$
      .pipe(takeUntil(this.kill$))
      .subscribe(notification => {
        const { message, action, duration } = notification;

        this.snackBar.open(message, action, { duration });
      });
  }

  ngOnDestroy(): void {
    this.kill$.next(true);
  }
}
