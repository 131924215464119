<fuse-alert *transloco="let t; read: 'FORM_ERROR_NOTIFICATIONS'" class="mb-6" [type]="type">
  <ng-container fuseAlertTitle [ngSwitch]="type">
    <span *ngSwitchCase="'error'">{{ t('Error') }} </span>
    <span *ngSwitchCase="'warning'">{{ t('Warning') }}</span>
  </ng-container>
  <ng-container [ngSwitch]="error">
    <ng-container *ngSwitchCase="'exception.invalidUrlFormat'">{{ t('Invalid url format') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.invalidFirstNameLength'">{{ t('First name should have length between 3 and 32 signs') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.invalidLastNameLength'">{{ t('Last name should have length between 3 and 64 signs') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.auth.emailNotMatching'">{{ t('Email does not match regex, pleaseRefer to RFC 2822 standard email validation') }}</ng-container>
    <ng-container *ngSwitchCase="'error.InternalServerError'">{{ t('Internal server error') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.auth.incorrect2fa'">{{ t('Incorrect 2fa code') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.nip.incorrectFormat'">{{ t('The nip provided has incorrect format') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.phoneNumberIncorrectFormat'">{{ t('Number with incorrect format') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.merchantAlreadyExists'">{{ t('Merchant already exists') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.merchantUserWithProvidedEmailAlreadyExists'">{{ t('Merchant user with provided email alread exists') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.emailNotProvidedCantBeVerified'">{{ t('Email was not provided, hence it cannot be verified') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.mfaNotFound'">{{ t('Mfa not found') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.invalidEmail'">{{ t('Invalid email address') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.noPhoneNumberProvided'">{{ t('Phone not provided, hence it cannot be verified') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.allConsentsMustBeAccepted'">{{ t('All consents must be accepted') }}</ng-container>
    <ng-container *ngSwitchCase="'exception.resend.tooQuickly'">{{ t('Resend code used too quickly') }}</ng-container>
</ng-container>
  <ul *ngIf="error.length">
    <li *ngFor="let err of error">
      <ng-container [ngSwitch]="err.errorCode">
        <ng-container *ngSwitchCase="'minItems.openapi.requestValidation'">{{ t('representatives must NOT have fewer than 1 items') }}</ng-container>
        <ng-container *ngSwitchDefault>{{err.message}}</ng-container>
      </ng-container>
    </li>
  </ul>
</fuse-alert>