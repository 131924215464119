import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AppWalletTrxV4, PgWalletTrx } from 'app/api/generated';

export interface WalletsState extends EntityState<PgWalletTrx, string>, ActiveState {
  last?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wallets', idKey: '_id' })
export class WalletsStore extends EntityStore<WalletsState, PgWalletTrx, string> {
  constructor() {
    super();
  }

  akitaPreAddEntity(item: PgWalletTrx): PgWalletTrx & { _id: string; } {
    return {
      ...item,
      _id: `${item.walletid}-${item.trxid}-${item.userid}`,
    };
  }
}
