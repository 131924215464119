import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MerchantUserPrivilege } from 'app/api/generated';

@Directive({
  selector: '[appShowForRole]'
})
export class ShowForRoleDirective {
  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authService: AuthService) {}

  @Input() set appShowForRole(roles: MerchantUserPrivilege[]) {

    const userPermissions = this.authService.userPermissions;

    if (!userPermissions) {
      return;
    }
    const isAuthorized = roles.some(element => userPermissions.includes(element));
    if (isAuthorized) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
