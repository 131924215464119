/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { InitUserInviteRequest } from '../models/InitUserInviteRequest';
import type { Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_ } from '../models/Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_';
import type { TwoFactorConfirmRequest } from '../models/TwoFactorConfirmRequest';
import type { VerifyPhoneInitRequest } from '../models/VerifyPhoneInitRequest';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserInviteService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param last
     * @param limit
     * @returns Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_ Ok
     * @throws ApiError
     */
    public listInvites(
        last?: string,
        limit?: number,
    ): Observable<Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/userInvite/list',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public inviteUser(
        requestBody: InitUserInviteRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/init',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public initLoginDataChange(
        requestBody: {
            department?: string;
            name: string;
            email: string;
            merchantUserId: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/initLoginDataChange',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantAddUserToken
     * @returns any Ok
     * @throws ApiError
     */
    public getInvitation(
        merchantAddUserToken: string,
    ): Observable<{
        companyName: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/userInvite',
            headers: {
                'merchant-add-user-token': merchantAddUserToken,
            },
        });
    }

    /**
     * @param merchantAddUserToken
     * @returns void
     * @throws ApiError
     */
    public sendEmail(
        merchantAddUserToken: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/sendEmail',
            headers: {
                'merchant-add-user-token': merchantAddUserToken,
            },
        });
    }

    /**
     * @param merchantAddUserToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public confirmEmail(
        merchantAddUserToken: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/confirmEmail',
            headers: {
                'merchant-add-user-token': merchantAddUserToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantAddUserToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public sendSms(
        merchantAddUserToken: string,
        requestBody: VerifyPhoneInitRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/sendSms',
            headers: {
                'merchant-add-user-token': merchantAddUserToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantAddUserToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public confirmSms(
        merchantAddUserToken: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/confirmSms',
            headers: {
                'merchant-add-user-token': merchantAddUserToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public changeEmail(
        merchantUserId: string,
        requestBody: {
            email: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/{merchantUserId}/changeEmail',
            path: {
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public verifyEmail(
        merchantUserId: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/{merchantUserId}/verifyEmail',
            path: {
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public changePhoneNumber(
        merchantUserId: string,
        requestBody: {
            phoneNumber: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/{merchantUserId}/changePhoneNumber',
            path: {
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public verifyPhoneNumber(
        merchantUserId: string,
        requestBody: TwoFactorConfirmRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/userInvite/{merchantUserId}/verifyPhoneNumber',
            path: {
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}