import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {IntegrationProfileResponse} from 'app/api/generated';

export interface IntegrationProfileState extends EntityState<IntegrationProfileResponse, string>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'integrationProfiles', idKey: 'integrationProfileId' })
export class IntegrationProfilesStore extends EntityStore<IntegrationProfileState, IntegrationProfileResponse, string> {
  constructor() {
    super() ;
  }
}
