import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'app/core/user/user.service';
import { MerchantUser } from 'app/api/generated';
import { UserExt } from 'app/core/user/user.types';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar = true;
  user: UserExt;

  private _unsubscribeAll = new Subject<boolean>();

  constructor(private _changeDetectorRef: ChangeDetectorRef, private _router: Router, private _userService: UserService) {}

  ngOnInit(): void {
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: UserExt) => {
      this.user = user;

      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  updateUserStatus(status: string): void {
    // TODO: Probably we don't need this "status" feature at all
  }

  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  goToSettings() {
    this._router.navigate(['/settings']);
  }
}
