import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LayoutScaffoldComponent } from './components/layout-scaffold/layout-scaffold.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OaNotificationsComponent } from './notifications/oa-notifications/oa-notifications.component';
import {EnableControlDirective} from '@openapp/directives/EnableControlDirective';
import {EnableFormGroupDirective} from '@openapp/directives/EnableFormGroupDirective';
import { FormErrorNotificationsComponent } from './notifications/form-error-notifications/form-error-notifications.component';
import { FuseAlertModule } from '@fuse/components/alert';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    FuseAlertModule,
    TranslocoModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    LayoutScaffoldComponent,
    EmptyStateComponent,
    ErrorStateComponent,
    OaNotificationsComponent,
    EnableControlDirective,
    EnableFormGroupDirective,
    FormErrorNotificationsComponent,
  ],
  exports: [
    ReactiveFormsModule,
    LayoutScaffoldComponent,
    EmptyStateComponent,
    ErrorStateComponent,
    OaNotificationsComponent,
    EnableControlDirective,
    EnableFormGroupDirective,
    FormErrorNotificationsComponent
  ]
})
export class OpenAppModule {}
