import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    const authToken = this._authService.getToken();

    if (authToken && !AuthUtils.isTokenExpired(authToken)) {
      newReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`),
      });
    }

    var refund = new RegExp('merchantPanel/v1/integrationProfiles/[\\S]{0,}/refund$');
    var paymentHistory = new RegExp('/merchantPanel/v1/integrationProfiles/[\\S]{0,}/order/[\\S]{0,}/refund/[\\S]{0,}/pdf$');

    if (refund.test(req.url)) {
      newReq = newReq.clone({
        responseType: 'text',
      });
    }

    if (paymentHistory.test(req.url)) {
      newReq = newReq.clone({
        responseType: 'blob',
      });
    }

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this._authService.signOut();
          location.reload();
        }
        return throwError(error?.error || error);
      })
    );
  }
}
