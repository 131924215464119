import {Directive, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroupDirective, FormGroupName} from '@angular/forms';

@Directive({
  selector: '[enableFormGroup]',
})
export class EnableFormGroupDirective implements OnChanges {
  private FormGroupNameDirective: FormGroupName;
  private FormGroupDirective: FormGroupDirective;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.enableFormGroup) {
      this.enableOrDisableFormGroup(changes.enableFormGroup.currentValue);
    }
  }

  @Input() enableFormGroup = false;


  constructor(
    private injector: Injector,
  ) {
    try {
      this.FormGroupNameDirective = injector.get(FormGroupName);
    } catch (e) {
    }
    try {
      this.FormGroupDirective = injector.get(FormGroupDirective);
    } catch (e) {
    }

    if (!this.FormGroupDirective && !this.FormGroupNameDirective) {
      console.error('EnableFormGroupDirective requires formGroup or formGroupName');
    }
  }

  enableOrDisableFormGroup(enable: boolean) {
    const action = enable ? 'enable' : 'disable';

    // todo: should check if parent component is disabled, and prevent enable() if so

    if (this.FormGroupDirective) {
      this.FormGroupDirective.control[action]();
    } else if (this.FormGroupNameDirective) {
      this.FormGroupNameDirective.control[action]();
    }
  }


}
